.MuiFormControl-root, .MuiTextField-root {
  margin: .8em 1em!important;
  width: 80%;
  box-sizing: border-box;
  border: none;
  
  /* box-shadow: ; */
}

.MuiOutlinedInput-notchedOutline {
  border: none!important;
  border-radius: 13px!important;
  box-shadow: 2px 2px 4px 0px #f5f5f5, -6px -6px 13px 5px #fefefe;
}

.MuiInput-underline:before {
  border-color: #f4f4f4!important;
}