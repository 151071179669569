@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: 'Lato', sans-serif;
  min-height: 700px;
}


.section-container {
  padding-bottom: 4em;
  width: 650px;
  margin: 0 auto;
  max-width: 100%;
}

.section-title {
  padding: 1.5em;
}

.nav {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  text-align: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  /* border-top: 1px solid #eee; */
  bottom: 0;
  margin: 0;
  width: 300px;
  margin: 0 auto;
  max-width: 100%;
}

.nav li {
  font-family: 'Lato', sans-serif;
  padding: .6em 1em;
  padding-bottom: 1em;
  /* padding-bottom: 2em; */
  flex-grow: 1;
  text-transform: uppercase;
  font-weight: 500;
  color: #aaa;
  /* border: 1px solid #eee; */
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  /* box-shadow: 0px 2px 4px 0px #fff;
  box-shadow: 0px 2px 2px 0px #fafafa; */
}

.nav li:nth-child(2).active {background: #38e73f;}
.nav li:nth-child(3).active {background: #851df1;}

.nav .active {
  color: white;
  background: #fc0d5e;
  font-weight: 600;
}
.section-container {
  /* width: 100%; */
  text-align: center;
  padding-top: 1.5em;
  width: 650px;
  margin: 0 auto;
  max-width: 100%;
}

.MuiFormControl-root, .MuiTextField-root {
  margin: .8em 1em!important;
  width: 80%;
  box-sizing: border-box;
  border: none;
  
  /* box-shadow: ; */
}

.MuiOutlinedInput-notchedOutline {
  border: none!important;
  border-radius: 13px!important;
  box-shadow: 2px 2px 4px 0px #f5f5f5, -6px -6px 13px 5px #fefefe;
}

.MuiInput-underline:before {
  border-color: #f4f4f4!important;
}

.MuiInputBase-input {
  color: #777!important;
  padding-left: .6em!important;
}

.MuiFormControl-root, .MuiTextField-root {
  margin: .8em 1em!important;
  width: 80%;
  box-sizing: border-box;
  border: none;
  
  /* box-shadow: ; */
}

.MuiOutlinedInput-notchedOutline {
  border: none!important;
  border-radius: 13px!important;
  box-shadow: 2px 2px 4px 0px #f5f5f5, -6px -6px 13px 5px #fefefe;
}

.MuiInput-underline:before {
  border-color: #f4f4f4!important;
}
