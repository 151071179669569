@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');

.App {
  font-family: 'Lato', sans-serif;
  min-height: 700px;
}


.section-container {
  padding-bottom: 4em;
  width: 650px;
  margin: 0 auto;
  max-width: 100%;
}

.section-title {
  padding: 1.5em;
}

.nav {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  text-align: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  /* border-top: 1px solid #eee; */
  bottom: 0;
  margin: 0;
  width: 300px;
  margin: 0 auto;
  max-width: 100%;
}

.nav li {
  font-family: 'Lato', sans-serif;
  padding: .6em 1em;
  padding-bottom: 1em;
  /* padding-bottom: 2em; */
  flex-grow: 1;
  text-transform: uppercase;
  font-weight: 500;
  color: #aaa;
  /* border: 1px solid #eee; */
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  /* box-shadow: 0px 2px 4px 0px #fff;
  box-shadow: 0px 2px 2px 0px #fafafa; */
}

.nav li:nth-child(2).active {background: #38e73f;}
.nav li:nth-child(3).active {background: #851df1;}

.nav .active {
  color: white;
  background: #fc0d5e;
  font-weight: 600;
}